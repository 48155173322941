// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-applications-index-tsx": () => import("./../../../src/pages/applications/index.tsx" /* webpackChunkName: "component---src-pages-applications-index-tsx" */),
  "component---src-pages-distributors-index-tsx": () => import("./../../../src/pages/distributors/index.tsx" /* webpackChunkName: "component---src-pages-distributors-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-odour-neutraliser-products-index-tsx": () => import("./../../../src/pages/odour-neutraliser-products/index.tsx" /* webpackChunkName: "component---src-pages-odour-neutraliser-products-index-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-retailers-index-tsx": () => import("./../../../src/pages/retailers/index.tsx" /* webpackChunkName: "component---src-pages-retailers-index-tsx" */),
  "component---src-templates-product-type-product-type-tsx": () => import("./../../../src/templates/product-type/productType.tsx" /* webpackChunkName: "component---src-templates-product-type-product-type-tsx" */)
}

